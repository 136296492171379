







































.single_number_chart {
  .number {
    font-size: 5rem;
    border: 3px solid white !important;

    .title {
      position: relative;
      top: 15px;
      font-size: 1rem !important;
    }
  }
}
